
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







































































































.jobs-listing-title__title {
  margin-bottom: 0;
  line-height: 1.5;
}

.jobs-listing-title__sub {
  @include liquid(margin-bottom, 20px, 30px);
}

.jobs-listing-title__picture-container {
  position: relative;
  width: 100%;
  background-color: $c-dev-info;
  aspect-ratio: 2792/1096;
  cursor: pointer;
}

.jobs-listing-title__picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  transition: all $transition-duration $easing;

  &.back {
    z-index: 1;
    opacity: 0;
  }

  .jobs-listing-title__picture-container:hover & {
    &.back {
      opacity: 1;
    }
  }
}

.jobs-listing-title__description {
  // prettier-ignore
  @include fluid(padding-block, (xxs: 30px, xxl: 60px));

  margin-inline: auto;
  margin-bottom: 0;
  text-align: center;
}
