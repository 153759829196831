
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































































.values-grid {
  @include liquid(padding-block, 40px, 120px);
}

.values-grid__title {
  margin-bottom: 2rem;

  ::v-deep {
    strong {
      display: block;
      font-size: clamp(3rem, 3.571vw, 6rem);
      font-weight: 600;
      line-height: 1.3;
    }
  }
}

.values-grid__items {
  @extend %list-nostyle;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 0.4rem;

  @include mq(l) {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;

    > li:nth-child(1) {
      transform: translateY(50%);
    }

    > li:nth-child(3) {
      grid-row-start: 2;
      grid-column-start: 2;
    }

    > li:nth-child(4) {
      grid-row-start: 1;
      grid-column-start: 3;
      transform: translateY(-15rem);
    }

    > li:nth-child(5) {
      grid-column-start: 3;
      transform: translateY(-15rem);
    }
  }
}

.values-grid__item {
  cursor: pointer;
}
