
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















































































































































































































































































































































::v-deep {
  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 10em $c-input-bg inset;
    -webkit-text-fill-color: $c-text-light;
  }
}

.text-feedback {
  margin: 2rem 0 0;
  font-size: 1.5rem;
  font-weight: 800;
}

.form-feedback {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include mq($from: s) {
    flex-direction: row;
    align-items: center;
  }
}

.form-feedback__icon {
  @include liquid(width, 80px, 100px);
  @include liquid(height, 80px, 100px);
}

.form-feedback__content {
  @include liquid(font-size, 20px, 30px);

  margin-top: 1.5rem;
  font-weight: 600;
  line-height: 1.5;

  @include mq($from: s) {
    margin-top: 0;
    margin-left: 4.5rem;
  }
}

.col-captcha {
  @include mq($from: l) {
    flex-grow: 0;
    margin-bottom: 0;
  }
}

.col-submit-button {
  @include mq($from: l) {
    margin-right: 8.333%;
  }
}

.subgrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .half {
    width: 100%;

    @include mq($from: l) {
      width: 47%;
    }
  }
}

.checkbox {
  position: relative;
  display: flex;
  align-items: center;
}

input {
  @extend %visually-hidden;

  border: 0;

  &:checked + label::after {
    display: block;
  }

  &:focus-visible + label {
    outline-color: $c-main;
  }
}

.label {
  padding: 0 0 0 3.5rem;
  color: $c-white;
  font-size: 1.8rem;
  line-height: 3rem;
  border-radius: 0.2rem;
  outline-offset: 0.5rem;
  outline: 2px transparent solid;

  ::v-deep {
    a {
      text-decoration: underline;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    width: 2.4rem;
    height: 2.4rem;
    border: 0.1rem solid $c-white;
    border-radius: 0.4rem;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0.76rem;
    left: 0.61rem;
    display: none;
    width: 1.2rem;
    height: 1.2rem;
    background: $c-main;
    border-radius: 0.2rem;
  }
}
