
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





































































.values-card {
  position: relative;
  overflow: hidden;
  padding: 7rem 2rem 2rem;
  color: $c-white;

  @include mq(l) {
    padding: 50rem 3rem 3rem;
  }
}

.values-card__picture {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  transition: all $easing $transition-duration;

  .values-card:hover & {
    transform: scale(1.1);
  }
}

.values-card__content {
  display: flex;
  align-items: center;
}

.values-card__icon {
  margin-right: 1rem;
  fill: $c-main;
}

.values-card__arrow {
  stroke: $c-white;
  width: 5rem;
  margin-left: auto;
}
