
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





























































































.apply {
  ::v-deep {
    .form .contact-title {
      display: none;
    }
  }
}

.apply__hero {
  display: flex;
  align-items: flex-end;
  color: $c-text-light;
  background-color: $c-surface-dark;

  > .row {
    width: 100%;
  }
}

.apply__hero__title {
  margin-bottom: 2rem;
}

.apply__section {
  @include liquid(padding-top, 50px, 70px);
}

.main-service-title {
  @include liquid(font-size, 20px, 40px);

  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 600;
}

.contact-baseline {
  @include liquid(font-size, 15px, 20px);
  @include liquid(margin-bottom, 40px, 46px);

  color: $c-main;
  font-weight: 400;
}

.hr {
  @include liquid(margin-bottom, 15px, 35px);

  margin-top: 0;
}

::v-deep {
  .service {
    .curtain {
      width: 100%;
    }
  }

  .wysiwyg {
    @include liquid(font-size, 15px, 20px);

    line-height: 1.5;

    p {
      margin-bottom: 1rem;
    }

    a {
      &[href^='mailto:'],
      &[href^='tel:'] {
        text-decoration: none;
      }
    }
  }

  .address {
    font-style: initial;

    p {
      &:not(:last-of-type) {
        margin-bottom: 2em;
      }
    }
  }

  .bleed {
    background-color: $c-surface-light-accent;
  }
}
