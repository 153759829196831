
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























































































.quote {
  padding-block: 8rem;
}

.quote-inner {
  @include mq(l) {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
  }
}

.quote__content {
  @include mq(l) {
    width: col(11, 20);
    margin-left: col(2, 20);
  }
}

.quote__content__icon {
  fill: $c-main;
}

.quote__content__htmltext {
  @include liquid(margin-bottom, 20px, 40px);

  margin-top: 2rem;
}

.quote__content__name {
  @include liquid(font-size, 18px, 20px);
  @include liquid(line-height, 26px, 20px);

  font-weight: 600;
}

.quote__content__job {
  @include liquid(line-height, 24px, 30px);

  color: $c-gray-dark;
  font-size: 1.5rem;
}

.quote__picture-container {
  position: relative;
  overflow: hidden;
  aspect-ratio: 1;

  ::v-deep {
    .clip {
      aspect-ratio: 1;
    }

    .quote__picture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @include mq($until: l) {
    margin-top: 4rem;
  }

  @include mq(l) {
    width: col(7, 20);
  }
}
