
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






































































































































































































































































































.values-panel {
  // prettier-ignore
  @include fluid(padding, (xxs: 20px, xl: 40px));
  @include get-all-space;

  position: fixed;
  z-index: 999;
  background: rgba($c-black, 0.75);
}

.values-panel-inner {
  @include center-xy;

  position: relative;
  display: flex;
  width: 100%;
  max-width: 130rem;
  background-color: $c-main;
}

.values-panel__title {
  display: flex;
  width: 8rem;
  padding: 1.7rem 1.7rem 6rem;
  background-color: $c-white;
}

.values-panel__title-inner {
  display: flex;
  align-items: center;
  writing-mode: vertical-rl;
  transform: rotateZ(-180deg);
}

.values-card__icon {
  margin-bottom: 1rem;
  fill: $c-main;
}

.values-panel__visual {
  position: relative;
  flex: 0 0 44rem;
  aspect-ratio: 445/640;

  @include mq($until: l) {
    display: none;
  }
}

.values-panel__visual__picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.values-panel__description {
  // prettier-ignore
  @include fluid(font-size, (xxs: 15px, xl: 20px));

  margin-inline: auto;
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  padding: 2rem;
  color: $c-white;
  font-size: 20px;
  line-height: 1.5;

  ::v-deep {
    .g-wysiwyg {
      padding-inline: 1.4rem;
    }
  }
}

.values-panel__navigation {
  margin-top: auto;

  button {
    @extend %button-nostyle;

    cursor: pointer;
    stroke: $c-white;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
}

.values-card__arrow {
  fill: $c-white;
  width: 5rem;
  margin-left: auto;
}

.values-panel__close {
  @extend %button-nostyle;

  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  fill: $c-white;
}
